import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { optionsMenu } from './core/constantes/menu';
import { IErrorCatch, IMenu } from './core/models';
import { AlertsService } from './core/services/alerts/alerts.service';
import { ApiUserService } from './core/services/apiUser/api-user.service';
import { ApplicationService } from './core/services/application.service';
import { ThemeService } from './core/services/theme.service';
import { animationNgIf } from './shared/animations/animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [animationNgIf]
})
export class AppComponent implements OnInit {
    title = 'crm';
    showMenu = true;
    userAuthenticated: boolean = false;

    optionsMenu: IMenu[] = optionsMenu;
    itemAtivo: IMenu = {} as IMenu;
    loadingPage: boolean = false;
    changeState: boolean = false;
    isDarkTheme: boolean = this._themeService.isDarkTheme();
    windowWidth: number = window.innerWidth;
    userInfo: any = {};

    constructor(
        private _router: Router,
        private _apiUser: ApiUserService,
        private _alerts: AlertsService,
        private _application: ApplicationService,
        private _themeService: ThemeService,
        private _platform: Platform
    ) {
        this.checkUserAuthenticated();
    }

    @HostListener('window:resize')
    onResize() {
        this.windowWidth = window.innerWidth;
    }

    ngOnInit() {
        this.checkPlatform();

        this._application.listeningLoadingPage().subscribe((status) => {
            setTimeout(() => {
                this.loadingPage = status;
            });
        });

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const path = event.url;
                this.setRouteActive(path);
            }
        });
    }

    setRouteActive(path: string) {
        this.optionsMenu.forEach((item: IMenu) => {
            const _path = path.split('/');
            if (item.path?.startsWith('/' + _path[1])) {
                this.itemAtivo = item;
            }
        });
    }

    checkPlatform() {
        // if ((this._platform.ANDROID || this._platform.IOS) && this.windowWidth < 992) {
        if (this.windowWidth < 992) {
            this.showMenu = !this.showMenu;
        }
    }

    handleOptionClick(option: IMenu): void {
        if (option.path) {
            this._router.navigate([option.path]);
        } else if (option.action === 'logout') {
            this.logout();
        }
    }

    checkUserAuthenticated() {
        this._apiUser.checkUserAuthenticated().subscribe((authenticated) => {
            this.userAuthenticated = authenticated;
            if (authenticated) {
                this.getUserInfo();
            }
        });
    }

    getUserInfo() {
        this.userInfo = this._apiUser.getUserInfo();
    }

    async logout() {
        try {
            await this._apiUser.signOut();
            this._router.navigateByUrl('/sign-in');
            this._apiUser.setUserAuthenticated(false);
            localStorage.clear();
        } catch (error) {
            console.error(error);
            const { error: _error } = error as IErrorCatch;
            this._alerts.openMessage(_error.message, 'OK');
        }
    }
}
