import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { map } from 'rxjs';
import { ApiUserService } from '../services/apiUser/api-user.service';

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router = inject(Router);
    const apiUser = inject(ApiUserService);

    return apiUser.checkUserAuthenticated().pipe(
        map((authenticated) => {
            if (authenticated && !!apiUser.getSessionId()) return true;

            router.navigateByUrl('/sign-in');
            return false;
        })
    );
};
