import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {
    constructor(private _snackBar: MatSnackBar) {}

    openMessage(message: string, action: string = 'OK', duration: number = 8000) {
        this._snackBar.open(message, action, { duration });
    }

    openFromComponent(component: any, duration: number = 8000) {
        this._snackBar.openFromTemplate(component, {
            duration
        });
    }

    mostrarMensagemErroHttp(message = 'Houve um erro, tente novamente!') {
        this.openMessage(message, 'OK');
    }
}
