import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalConfirmComponent } from './modal-confirm.component';

@NgModule({
    declarations: [ModalConfirmComponent],
    imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class ModalConfirmModule {}
