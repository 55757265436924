import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initialsName'
})
export class InitialsNamePipe implements PipeTransform {
    transform(value: string): unknown {
        if (!value) return value;
        const partes = value.split(' ');
        if (partes.length < 2) {
            return '';
        }
        return (partes[0][0] + partes[partes.length - 1][0]).toUpperCase();
    }
}
