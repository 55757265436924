import { IMenu } from '../models';

export const optionsMenu: IMenu[] = [
    {
        title: 'Dashboard',
        icon: 'dashboard',
        path: '/dashboard'
    },
    {
        title: 'Negociações',
        icon: 'handshake',
        path: '/negociacoes'
    },
    {
        title: 'Vendas',
        icon: 'attach_money',
        path: '/vendas'
    },
    {
        title: 'Clientes',
        icon: 'group',
        path: '/clientes'
    },
    {
        title: 'Estoque',
        icon: 'inventory_2',
        path: '/estoque'
    },
    // {
    //     title: 'Financeiro',
    //     icon: 'account_balance',
    //     path: '/financeiro'
    // },
    // {
    //     title: 'Relatórios',
    //     icon: 'article_outline',
    //     path: '/relatorios'
    // },
    {
        title: 'Usuários',
        icon: 'manage_accounts',
        path: '/usuarios'
    },
    {
        title: 'Sair',
        icon: 'logout',
        action: 'logout'
    }
];
