import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { generateApiParamsToDataTable } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import {
    IDataTableParamsApi,
    IDataTableResponse,
    IUser,
    IUserAuthentication,
    IUserAuthenticationResponse,
    IUserConfirmEmail,
    IUserRecoverPassword,
    IUserRedefinePassword,
    IUserRegister
} from '../../models';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ApiUserService {
    URL = environment.apiUrl;

    private _userSession!: any;
    private _userAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _http: HttpClient,
        private _localStorage: LocalStorageService,
        private _router: Router
    ) {
        this.setUserAuthenticated(!!this.getSessionId());
    }

    createUser(body: IUser): Promise<IUser> {
        return lastValueFrom(
            this._http.post<IUser>(`${this.URL}/user`, body, {
                withCredentials: true
            })
        );
    }

    updateUser(body: IUser, id: string): Promise<IUser> {
        return lastValueFrom(
            this._http.put<IUser>(`${this.URL}/user/${id}`, body, {
                withCredentials: true
            })
        );
    }

    getUser(id: string): Promise<IUser> {
        return lastValueFrom(
            this._http.get<IUser>(`${this.URL}/user/${id}`, {
                withCredentials: true
            })
        );
    }

    register(body: IUserRegister): Promise<IUserAuthenticationResponse> {
        return lastValueFrom(
            this._http.post<IUserAuthenticationResponse>(`${this.URL}/register`, body, {
                withCredentials: true
            })
        );
    }

    signIn(body: IUserAuthentication): Promise<IUserAuthenticationResponse> {
        return lastValueFrom(
            // this._http.post<IUserAuthenticationResponse>(`${this.URL}/signin`, body, {
            this._http.post<IUserAuthenticationResponse>(`${this.URL}/signin`, body, {
                withCredentials: true
            })
        );
    }

    signOut(): Promise<void> {
        return lastValueFrom(
            this._http.post<void>(
                `${this.URL}/signout`,
                { session_id: this.getSessionId() },
                { withCredentials: true }
            )
        );
    }

    signOutForced(): void {
        this._router.navigateByUrl('/sign-in');
        this.setUserAuthenticated(false);
        localStorage.clear();
    }

    refreshToken(): Observable<IUserAuthenticationResponse> {
        return this._http.post<IUserAuthenticationResponse>(
            `${this.URL}/refresh`,
            {},
            { withCredentials: true }
        );
    }

    recoverPassword(body: IUserRecoverPassword): Promise<void> {
        return lastValueFrom(this._http.post<void>(`${this.URL}/recuperar-senha`, body));
    }

    redefinePassword(body: IUserRedefinePassword): Promise<void> {
        return lastValueFrom(this._http.post<void>(`${this.URL}/redefinir-senha`, body));
    }

    confirmEmail(body: IUserConfirmEmail): Promise<IUserConfirmEmail> {
        return lastValueFrom(
            this._http.post<IUserConfirmEmail>(`${this.URL}/confirma-email`, body)
        );
    }

    setSession(
        session_id: string,
        email: string,
        name: string,
        admin: boolean,
        configs: any,
        avatar: string,
        userId: string
    ) {
        this._localStorage.setItem('session_id', session_id);
        this._localStorage.setItem(
            'user',
            JSON.stringify({
                name,
                email,
                admin,
                configs: configs || {},
                avatar: avatar || null,
                userId
            })
        );
    }

    setUserAuthenticated(status: boolean) {
        this._userAuthenticated$.next(status);
    }

    checkUserAuthenticated(): Observable<boolean> {
        return this._userAuthenticated$.asObservable();
    }

    getUserInfo() {
        const userInfo = this._localStorage.getItem('user');
        return userInfo ? JSON.parse(userInfo) : {};
    }

    getRefreshToken() {
        return this._localStorage.getItem('refresh_token');
    }

    getSessionId() {
        return this._localStorage.getItem('session_id');
    }

    listUsers(params: IDataTableParamsApi): Promise<IDataTableResponse<IUser>> {
        return lastValueFrom(
            this._http.get<IDataTableResponse<IUser>>(
                `${this.URL}/users?${generateApiParamsToDataTable(params)}`,
                {
                    withCredentials: true
                }
            )
        );
    }

    private _decodeToken(token: string) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedToken = JSON.parse(
                CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8)
            );
            return decodedToken;
        } catch (error) {
            console.error('Erro ao decodificar o token:', error);
        }
    }
}
