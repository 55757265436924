import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    isDarkTheme() {
        return this.document.body.classList.contains('dark-theme');
    }
}
