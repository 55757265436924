import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    private _loadingPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    setLoadingPage(status: boolean) {
        this._loadingPage$.next(status);
    }

    listeningLoadingPage(): Observable<boolean> {
        return this._loadingPage$.asObservable();
    }
}
