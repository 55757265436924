import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/localStorage/local-storage.service';

@Injectable()
export class AuthenticatedInterceptor implements HttpInterceptor {
    constructor(private _localStorage: LocalStorageService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Verifique se a solicitação está indo para um domínio da "amazonaws"
        // if (request.url.includes('amazonaws')) {
        //     // Não inclua o cabeçalho de autorização para solicitações da "amazonaws"
        //     return next.handle(request);
        // }

        const accessToken = this._localStorage.getItem('access_token');

        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: accessToken
                }
            });
        }

        return next.handle(request);
    }
}
