import { animate, style, transition, trigger } from '@angular/animations';

export const animationNgIf = trigger('animNgIf', [
    transition(':enter', [
        style({ opacity: '0', maxWidth: '0px' }),
        animate('.2s', style({ opacity: '1', maxWidth: '100px' }))
    ]),
    transition(':leave', [
        style({ opacity: '1', maxWidth: '100px' }),
        animate('.2s', style({ opacity: '0', maxWidth: '0px' }))
    ])
]);
