// export const environment = {
//     production: true,
//     apiUrl: 'http://localhost:3000',
//     apiUser: 'https://tbsar0j270.execute-api.us-east-1.amazonaws.com/dev',
//     apiCompany: 'https://8rz90bxr3j.execute-api.us-east-1.amazonaws.com/dev',
//     apiProduct: 'https://eexh9r0tb2.execute-api.us-east-1.amazonaws.com/dev',
//     LOCAL_STORAGE_KEY: '16b350b6wera87df1e57a1eab',
//     LOCAL_STORAGE_IV: '0001000001002000'
// };

export const environment = {
    production: false,
    apiUrl: 'https://api-dev.nuvemcar.com',
    apiUser: 'https://tbsar0j270.execute-api.us-east-1.amazonaws.com/dev',
    apiCompany: 'https://8rz90bxr3j.execute-api.us-east-1.amazonaws.com/dev',
    apiProduct: 'https://eexh9r0tb2.execute-api.us-east-1.amazonaws.com/dev',
    LOCAL_STORAGE_KEY: '16b350b6wera87df1e57a1eab',
    LOCAL_STORAGE_IV: '0001000001002000'
};
