export const FORM_MSG_ERRO = {
    email: 'E-mail inválido',
    required: 'Campo obrigatório',
    minlength: 'Mínimo {{requiredLength}} caracteres',
    maxlength: 'Máximo {{requiredLength}} caracteres',
    apenasNumeros: 'Apenas números',
    senhaIncompativel: 'As senhas devem ser iguais',
    min: 'Número mínimo é {{min}}',
    telefoneInvalido: 'Número inválido',
    horaInvalida: 'Hora inválida',
    matDatepickerParse: 'Data inválida',
    cpfCnpjInvalido: 'Número Inválido',
    placaInvalida: 'Placa inválida'
};

export const COLORS = [
    { label: 'Amarelo', value: 1 },
    { label: 'Azul', value: 2 },
    { label: 'Bege', value: 3 },
    { label: 'Branco', value: 4 },
    { label: 'Bronze', value: 5 },
    { label: 'Cinza', value: 6 },
    { label: 'Dourado', value: 7 },
    { label: 'Indefinida', value: 8 },
    { label: 'Laranja', value: 9 },
    { label: 'Marrom', value: 10 },
    { label: 'Prata', value: 11 },
    { label: 'Preto', value: 12 },
    { label: 'Rosa', value: 13 },
    { label: 'Roxo', value: 14 },
    { label: 'Verde', value: 15 },
    { label: 'Vermelho', value: 16 },
    { label: 'Vinho', value: 17 }
];

export const CONDITION_VEHICLE = [
    { label: 'Novo', value: 1 },
    { label: 'Usado', value: 2 }
];

export const TYPE_VEHICLE = [
    { label: 'Auto', value: 1 },
    { label: 'Moto', value: 2 },
    { label: 'Caminhão', value: 3 }
];

export const TYPE_GAS = [
    { label: 'Álcool', value: 1 },
    { label: 'Álcool e gás natural', value: 2 },
    { label: 'Diesel', value: 3 },
    { label: 'Elétrico', value: 4 },
    { label: 'Gás natural', value: 5 },
    { label: 'Gasolina', value: 6 },
    { label: 'Gasolina e álcool', value: 7 },
    { label: 'Gasolina e elétrico', value: 8 },
    { label: 'Gasolina e gás natural', value: 9 },
    { label: 'Gasolina, álcool e gás natural', value: 10 },
    { label: 'Gasolina, álcool, gás natural e benzina', value: 11 }
];

export const TRANSMISSION = [
    { label: 'Automática', value: 1 },
    { label: 'Automatizada', value: 2 },
    { label: 'Automatizada dct', value: 3 },
    { label: 'CVT', value: 4 },
    { label: 'Manual', value: 5 },
    { label: 'Semi-automática', value: 6 }
];

export const VEHICLE_BRANDS = [
    { label: 'ASTON MARTIN', value: 'ASTON MARTIN' },
    { label: 'AUDI', value: 'AUDI' },
    { label: 'BMW', value: 'BMW' },
    { label: 'BYD', value: 'BYD' },
    { label: 'CAOA CHERY', value: 'CAOA CHERY' },
    { label: 'CHEVROLET', value: 'CHEVROLET' },
    { label: 'CITROËN', value: 'CITROËN' },
    { label: 'FERRARI', value: 'FERRARI' },
    { label: 'FIAT', value: 'FIAT' },
    { label: 'FORD', value: 'FORD' },
    { label: 'GWM', value: 'GWM' },
    { label: 'HONDA', value: 'HONDA' },
    { label: 'HYUNDAI', value: 'HYUNDAI' },
    { label: 'JAC', value: 'JAC' },
    { label: 'JAGUAR', value: 'JAGUAR' },
    { label: 'JEEP', value: 'JEEP' },
    { label: 'KIA', value: 'KIA' },
    { label: 'LAMBORGHINI', value: 'LAMBORGHINI' },
    { label: 'LAND ROVER', value: 'LAND ROVER' },
    { label: 'LEXUS', value: 'LEXUS' },
    { label: 'MASERATI', value: 'MASERATI' },
    { label: 'MCLAREN', value: 'MCLAREN' },
    { label: 'MERCEDES-BENZ', value: 'MERCEDES-BENZ' },
    { label: 'MINI', value: 'MINI' },
    { label: 'MITSUBISHI', value: 'MITSUBISHI' },
    { label: 'NISSAN', value: 'NISSAN' },
    { label: 'PEUGEOT', value: 'PEUGEOT' },
    { label: 'PORSCHE', value: 'PORSCHE' },
    { label: 'RAM', value: 'RAM' },
    { label: 'RENAULT', value: 'RENAULT' },
    { label: 'ROLLS-ROYCE', value: 'ROLLS-ROYCE' },
    { label: 'SERES', value: 'SERES' },
    { label: 'SUBARU', value: 'SUBARU' },
    { label: 'SUZUKI', value: 'SUZUKI' },
    { label: 'TOYOTA', value: 'TOYOTA' },
    { label: 'TROLLER', value: 'TROLLER' },
    { label: 'VOLKSWAGEN', value: 'VOLKSWAGEN' },
    { label: 'VOLVO', value: 'VOLVO' }
];
