import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { FORM_MSG_ERRO } from '../core/constantes/formulario';
import { IDataTableParamsApi } from '../core/models';

export function getHeadersFormUrlEncoded(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
}

function getMensagemFormatada(errorKey: string, errorValue: any): string {
    //busca a mensagem na constante 'FORM_MSG_ERRO' através do 'errorKey'
    let errorMessage = FORM_MSG_ERRO[errorKey as keyof typeof FORM_MSG_ERRO];

    if (errorMessage) {
        //procura se tem algum paramêtro para trocar dinamicamente pelo valor requerido no validator
        if (errorMessage.includes('{{requiredLength}}')) {
            errorMessage = errorMessage.replace('{{requiredLength}}', errorValue.requiredLength);
        }
    }

    return errorMessage;
}

export function getFormMensagemErro(control: AbstractControl) {
    if (control.errors) {
        // pegar o primeiro erro na ordem do objeto para mostrar
        const errorKey = Object.keys(control.errors)?.[0];
        if (errorKey) {
            const errorMessage = getMensagemFormatada(errorKey, control.errors[errorKey]);
            return errorMessage;
        }
    }
    return 'Campo inválido';
}

export function formatterMoedaBr(value: string | number) {
    if (!value) return '';
    if (typeof value === 'number') value = value.toString();
    // Convertendo a string para um número
    const valorNumerico = parseFloat(value);

    const format = valorNumerico.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    return format;
}

export function dateBr(value: string) {
    if (!value) return '';

    return moment(value).format('DD/MM/YYYY');
}

export function generateApiParamsToDataTable(params: IDataTableParamsApi) {
    let _params = new HttpParams();
    for (let param of Object.keys(params)) {
        const key = param as keyof IDataTableParamsApi;
        if (params[key] || key == 'page') {
            let value!: string;
            if (typeof params[key] === 'object') {
                const keys = Object.keys(params[key] as object);
                if (keys.length) value = JSON.stringify(params[key]);
            } else {
                value = String(params[key]);
            }
            if (value == '0' || value) _params = _params.set(key, value);
        }
    }

    return _params.toString();
}

export const setFormatPlate = (plate: string) => {
    if (!plate) return '';
    return plate.replace(/^([A-Za-z]+)-?([A-Za-z0-9]+)$/, '$1-$2');
};

export function convertToDecimal(value: any) {
    if (!value) return value;

    let formatted = value.replace(/\D/g, '');
    return parseInt(formatted) / 100;
}

export function formatCpf(value: string) {
    if (!value) return '';

    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');

    return value;
}

export function formatPlaca(value: string) {
    if (!value) return '';

    value = value.replace(/^([a-zA-Z0-9]{3})([a-zA-Z0-9]{4})$/, '$1-$2');

    return value;
}

export function formatFone(fone: string) {
    if (!fone) return fone;

    let format = fone.replace(/\D/g, '');
    format = format.replace(/^(\d{2})(\d)/g, '($1) $2');
    format = format.replace(/(\d)(\d{4})$/, '$1-$2');

    return format;
}

export function removeSpecialCharacters(texto: string) {
    if (!texto) return texto;
    const caracteresAcentuados = 'áàâãäéèêëíìîïóòôõöúùûüç';
    const caracteresSemAcento = 'aaaaaeeeeiiiiooooouuuuc';

    let textoNormalizado = '';

    for (let i = 0; i < texto.length; i++) {
        const caractere = texto[i];
        const indiceAcentuado = caracteresAcentuados.indexOf(caractere.toLowerCase());

        if (indiceAcentuado !== -1) {
            textoNormalizado += caracteresSemAcento[indiceAcentuado];
        } else if (caractere.match(/[^\w\s]/)) {
            // Ignorar caracteres especiais
        } else {
            textoNormalizado += caractere;
        }
    }

    return textoNormalizado;
}
