import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { IUserAuthenticationResponse } from '../models';
import { ApiUserService } from '../services/apiUser/api-user.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    constructor(private _apiUser: ApiUserService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && error?.error?.message === 'jwt expired') {
                    return this.handleTokenRefresh(request, next);
                } else if (
                    error.status === 401 &&
                    (error?.error?.message === 'jwt must be provided' ||
                        error?.error?.message === 'Invalid token' ||
                        error?.error?.message === 'Unauthorized')
                ) {
                    this._apiUser.signOutForced();
                    return throwError(() => error);
                }

                return throwError(() => error);
            })
        );
    }

    private handleTokenRefresh(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return this._apiUser.refreshToken().pipe(
            switchMap((refreshResponse: IUserAuthenticationResponse) => {
                // Atualize os tokens e faça a requisição original novamente
                this._apiUser.setSession(
                    refreshResponse.session_id,
                    refreshResponse.email,
                    refreshResponse.name,
                    refreshResponse.admin,
                    refreshResponse.configs,
                    refreshResponse.avatar,
                    refreshResponse.userId
                );

                return next.handle(request);
            }),
            catchError((error: any) => {
                // Lide com erros no refresh do token
                // Por exemplo, faça logout se não for possível atualizar o token
                this._apiUser.signOutForced();
                return throwError(() => error);
            })
        );
    }
}
