import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PublicRoutesGuard } from './core/guards/public-routes.guard';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'negociacoes',
        loadChildren: () =>
            import('./pages/negotiations/negotiations.module').then((m) => m.NegotiationsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'vendas',
        loadChildren: () => import('./pages/sales/sales.module').then((m) => m.SalesModule)
        // canActivate: [AuthGuard]
    },
    {
        path: 'clientes',
        loadChildren: () =>
            import('./pages/customers/customers.module').then((m) => m.CustomersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'estoque',
        loadChildren: () => import('./pages/stock/stock.module').then((m) => m.StockModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'financeiro',
        loadChildren: () =>
            import('./pages/financial/financial.module').then((m) => m.FinancialModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'relatorios',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'usuarios',
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/not-authorization/not-authorization.module').then(
                (m) => m.NotAuthorizationModule
            ),
        canActivate: [AuthGuard]
    },
    {
        path: 'sign-in',
        loadChildren: () => import('./pages/sign-in/sign-in.module').then((m) => m.SignInModule),
        canActivate: [PublicRoutesGuard]
    },
    {
        path: 'recover-password',
        loadChildren: () =>
            import('./pages/recover-password/recover-password.module').then(
                (m) => m.RecoverPasswordModule
            ),
        canActivate: [PublicRoutesGuard]
    },
    {
        path: 'redefine-password/:code',
        loadChildren: () =>
            import('./pages/redefine-password/redefine-password.module').then(
                (m) => m.RedefinePasswordModule
            ),
        canActivate: [PublicRoutesGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
