import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingPageComponent } from './loading-page.component';

@NgModule({
    declarations: [LoadingPageComponent],
    exports: [LoadingPageComponent],
    imports: [CommonModule, MatProgressSpinnerModule]
})
export class LoadingPageModule {}
