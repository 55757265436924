<mat-toolbar color="primary" *ngIf="userAuthenticated">
    <button mat-icon-button (click)="showMenu = !showMenu">
        <mat-icon>menu</mat-icon>
    </button>
    <!-- <img class="logo" src="assets/imgs/logo.png" alt="logo amploo"> -->
    <span>Nuvem Car</span>
    <span class="spacer"></span>
    <button mat-icon-button class="avatar" [matMenuTriggerFor]="menu">
        <span>{{userInfo?.name | initialsName}} </span>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
    </mat-menu>
</mat-toolbar>

<mat-drawer-container
    [ngClass]="['menu-lateral', isDarkTheme ? 'dark-theme' : '', !userAuthenticated ? 'full-height' : '']"
    (backdropClick)="checkPlatform()">
    <mat-drawer class="sidenav" [mode]="windowWidth >= 992 ? 'side' : 'over'"
        [opened]="windowWidth >= 992 ? true : showMenu" *ngIf="userAuthenticated">
        <mat-nav-list>

            <ng-container *ngFor="let row of optionsMenu">

                <mat-list-item class="menu-lateral-item"
                    [ngClass]="[!showMenu ? 'hidden' : '', itemAtivo === row ? 'active' : '']"
                    (click)="handleOptionClick(row); itemAtivo = row;" [matTooltip]="!showMenu ? row.title : ''"
                    matTooltipPosition="right">
                    <mat-icon matListItemIcon>{{row.icon}}</mat-icon>
                    <div *ngIf="showMenu" @animNgIf style="overflow: hidden;">{{row.title}}</div>
                </mat-list-item>

            </ng-container>

        </mat-nav-list>
    </mat-drawer>

    <mat-drawer-content
        [ngClass]="['sidenav-content', windowWidth >= 992 ? userAuthenticated ? !showMenu ? 'menu-icones__active' : 'menu-icones__desactive' : '' : '']">
        <router-outlet></router-outlet>

        <loading-page *ngIf="loadingPage"></loading-page>
    </mat-drawer-content>

</mat-drawer-container>