import { Component, Renderer2 } from '@angular/core';

@Component({
    selector: 'loading-page',
    templateUrl: './loading-page.component.html',
    styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent {
    constructor(private _render: Renderer2) {}

    ngOnInit() {
        const elem = document.querySelector('.mat-drawer-content');
        this._render.addClass(elem, 'overflowHidden');
    }

    ngOnDestroy() {
        const elem = document.querySelector('.mat-drawer-content');
        this._render.removeClass(elem, 'overflowHidden');
    }
}
